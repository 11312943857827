<template>
	<Layout>
		<template v-slot:other>
			<DialogLoginLine/>
		</template>
	</Layout>
</template>
<script>
	const Layout = require(`~/components/desktop/layouts/${process.env.DEF_webFrame}.vue`).default;
	import { mapState, mapMutations, mapGetters } from "vuex";
	import DialogLoginLine from "~/components/common/dialogLoginLine.vue";
	export default {
	  middleware: [
	  	"slideshow",
	  	"seo"
	  ],
	  components:{
	    Layout,
	    DialogLoginLine
	  },
	  mounted() {
      if (this.FirstAnnouncenent && this.ShowAnnouncenentPopup) {
        this._setFirstAnnouncenent(false);
        this.$root.$emit("bv::toggle::modal", "modalInfo", "#btnToggle");
      }
    },
	  methods: {
      ...mapMutations(['_setFirstAnnouncenent'])
    }, 
	  computed: {
      ...mapState(['FirstAnnouncenent']),
      ...mapGetters('webSetting', ['ShowAnnouncenentPopup'])
    }
	};
</script>