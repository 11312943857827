<template>
  <div></div>
</template>

<script>
  export default {
    props: {
      value: { type: Object, default () { return { Open: 0, Message: '' } } },
      title: { type: String, default () { return this.$t("store.tip") } },
      message: { type: String, default: '' },
      okTitle: { type: String, default () { return this.$t("store.btn.confirm") } },
      cancelTitle: { type: String, default () { return this.$t("store.btn.cancel") } }
    },
    methods: {
      showModal () {
        const h = this.$createElement
        const message = h('div', { domProps: { innerHTML: this.value.Message.replace(/\n/g, '<br>') } })
        this.$bvModal.msgBoxConfirm([message], {
          modalClass      : 'dialog-tips',
          titleClass      : 'title',
          titleHtml       : `<div class="circle"><i class="icon icon-note"></i></div><div class="title"><span> ${this.title} </span></div>`,
          size            : 'sm',
          okVariant       : 'submit',
          okTitle         : this.okTitle,
          cancelVariant   :'cancel',
          cancelTitle     : this.cancelTitle,
          centered        : true,
          hideHeaderClose : true
        })
        .then(value => {
          if (value) this.actions();
          this.allActions(value);
        })
        .catch(err => {
        })
      },
      actions () {
        this.$emit('actions')
      },
      allActions (ans) {
        this.$emit('allActions', ans)
      },
    },
    mounted() {
      this.$nextTick(function () {
        const sound_btn = document.getElementById('sound_btn');
        if (sound_btn) {
          sound_btn.click();
        }
      });
    },
    watch: {
      'value.Open' (Open) {
        this.showModal();
      },
    },
  };
</script>
