<template>
  <MsgBoxConfirm
    v-model="BoxConfirmSetting"
    :title="$t('store.bind_line')"
    :okTitle="$t('store.yes')"
    :cancelTitle="$t('store.no')"
    @allActions="Actions"
  />
</template>

<script>
  import { mapState, mapActions, mapMutations } from "vuex";
  import MsgBoxConfirm from "@/components/common/msgBoxConfirm.vue";
  export default {
    components: {
      MsgBoxConfirm
    },
    data() {
      return {
        BoxConfirmSetting: { Open: 0, Message: this.$t('store.has_registered_account') },
      };
    },
    created() {
    },
    mounted() {
      if (Object.keys(this.$route.query).indexOf('line_code') >= 0)
        this.LineLogin({ code: this.$route.query.line_code });
    },
    methods: {
      Toggle() {
        this.$root.$emit("bv::toggle::modal", "dialogLoginLine", "#btnToggle");
      },
      LineLogin(data) {
        this.CheckLogin({ type: 'Line', data }).then((Res) => {
          if (Object.keys(Res).indexOf('status') < 0 || Res.status != 'ok')
            return this.GoIndex();

          if (Object.keys(Res).indexOf('data') < 0) {
            let name = (this.IsMobile ? 'mobile-' : '') + 'user-profile';
            this.$router.push({ name });
            return this.$auth.fetchUser();
          }

          let Data = Res.data;

          if (Object.keys(Data).indexOf('bind') < 0)
            return this.GoIndex();
          
          if (Data.bind) {
            this.$auth.setUserToken(Data.token).then(() => {
              this.GetAccountData().then((res) => {
                this.GoIndex();
              });
            });
            return;
          }

          this._setLineCode(Data.code);
          this._setUpAgentLineCode(Data.dealer);
          this.BoxConfirmSetting.Open++; 
          return;
        });
      },
      Actions (Ans) {
        let name = Ans ? 'account-login' : 'account-signup';
        if (this.IsMobile)
          name = 'mobile-' + name; 

        this.$router.push({ name, params: undefined, query: {}});
      },
      GoIndex (Ans) {
        let name = (this.IsMobile ? 'mobile' : '');
        this.$router.push({ name });
      },
      ...mapActions("login", ["CheckLogin"]),
      ...mapActions("account", ["GetAccountData"]),
      ...mapMutations(['_setUpAgentLineCode']),
      ...mapMutations('login', ['_setLineCode'])
    },
    computed: {
      ...mapState(['IsMobile'])
    }
  };
</script>